$(function(){
    var popups = $('.popup');
    if (Cookies.get('cookie_consent') === 'set' && popups.length > 0) {
        $.each(popups, function () {
            var thatPopup = $(this);
            var thisID = thatPopup.attr('data-popupid');
            var popups_seen = typeof Cookies.get('popups_seen') == 'undefined' ? '' : Cookies.get('popups_seen');
            if (popups_seen.search(thisID) < 0) {
                var time = thatPopup.attr('data-eventtimer');
                setTimeout(() => {
                    new Modal(document.getElementById(thatPopup.attr('id')), {
                        'backdrop': 'static',
                        'keyboard': false
                    }).show();
                }, time);
            }
        });
    }
});




$('.popup').on('hidden.bs.modal', function (event) {
    if (navigator.cookieEnabled) {
        var modal = $(event.target);
        savePopupAsSeen(modal);
    }
});

$('.popups-seen-klicker').on('click', function (event) {
    if (navigator.cookieEnabled) {
        event.preventDefault();
        var modal = $(event.target).closest('.modal');
        savePopupAsSeen(modal);

        if (event.target.target == '_blank') {
            window.open(event.target.href, '_blank');
        } else {
            window.location = event.target.href;
        }

    }

});

var savePopupAsSeen = (modal) => {
    var modalID = modal.attr('data-popupid');
    if (typeof Cookies.get('popups_seen') !== "undefined") {
        /**
         * Wenn es den Cookie für Pop-Ups schon gibt,
         * prüfe ob dieses PopUp schon gesehen wurde
         * also in der Liste auftaucht.
         *
         * Falls nicht, hänge es als "gesehen" an die Liste an.
         */
        var oldList = Cookies.get('popups_seen');
        if (oldList.search(modalID) < 0) {
            Cookies.set('popups_seen', oldList + '.' + modalID, {expires: 14, secure: true, sameSite: 'strict', path: '/'})
        }
    } else {
        Cookies.set('popups_seen', modalID, {expires: 14, secure: true, sameSite: 'strict', path: '/'})
    }
}
