if($('body').hasClass('FormPage')){



    var oldSubmitVal = '';

    $(function(){
        oldSubmitVal = $('.one-clicker').val();

        grecaptcha.ready(function() {
            grecaptcha.execute('6Ld3myIeAAAAAOH2jLQEnrEMPpL7Gw2DjP5IkgZh', {action: 'submit'}).then(function(token) {
                document.getElementById('Form_CustomForm_google-token').value = token;
            });
        });

        $scrollToForm = false;

        $.each($('.message'), function($item){
            if($('.message').is(":visible")){
                $scrollToForm = true;
            }
        });

        if($scrollToForm){
            var el = $('#Form_CustomForm');
            var elOffset = el.offset().top;
            var elHeight = el.height();
            var windowHeight = $(window).height();
            var offset;

            if (elHeight < windowHeight) {
                offset = elOffset - ((windowHeight / 2) - (elHeight / 2));
            }
            else {
                offset = elOffset;
            }
            var speed = 700;
            var headerHeight = $('header').outerHeight();
            $('html, body').animate({scrollTop:offset - headerHeight - 32}, speed);
        }
    })

    $('.field input, .field textarea, .field select').on('input', function(){
        var submitButton = $('.one-clicker')
        submitButton.val(oldSubmitVal);
        submitButton.removeClass('disabled');
    });

    $('.one-clicker').on('click', function(e){
        var that = $(this);
        that.val('Bitte warten ...');
        that.addClass('disabled');
    });

}
