document.addEventListener("touchstart", function () {}, true);

Lightbox.option({
    resizeDuration: 200,
    wrapAround: true,
    albumLabel: "Bild %1 von %2",
    disableScrolling: true,
});

/**
 * ######################################
 * Sets first elementtext
 * Headline to H1
 * ######################################
 */
 if(!$('body').hasClass('ProductPage')){
    var classes = $(".elementtext").first().find("h2").attr("class");
    $(".elementtext")
        .first()
        .find("h2")
        .replaceWith(function () {
            return '<h1 class="' + classes + '">' + $(this).html() + "</h1>";
        });
    }

$('.product-slider').slick(
    {
        slidesToShow: 4,
        slidesToScroll: 1,
        rows: 0,
        dots: true,
        arrows: true,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 2000,
        speed: 850,
        prevArrow: $('.product-slider-prev'),
        nextArrow: $('.product-slider-next'),
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    }
);

$('.brand-slider').slick(
    {
        slidesToShow: 5,
        slidesToScroll: 1,
        rows: 0,
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 850,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
        ]
    }
);
