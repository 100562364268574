$(document).ready(() => {


    if($('body').hasClass('PrivacyPage')){
        var allHeadlines = $('.content-wrapper').find('h1, h2, h3, h4, h5, h6');
        var privacyContentList = $('.privacy-content-list').first();
        $.each(allHeadlines, function(index,item){
            $(this).attr('id', 'ph-'+index);
            privacyContentList.append('<li class="mb-2 privacy-content-item"><a href="'+location.href.replace(location.hash,"")+'#ph-'+index+'"><i class="fas fa-angle-right"></i>&nbsp;'+$(this).html()+'</a></li>')
        });

        $(privacyContentList).on('click', $('.privacy-content-item a'), function(e) {
            e.preventDefault();
            var target = e.target.href.split("#").pop();
            $('html,body').animate({
                scrollTop: $("#"+target+"").offset().top - 130
            }, 500);

        });
    }
});
